$side-nav-width: 50px;

.navigation {
  .side-nav {
    z-index: 50;
    width: $side-nav-width;

    i {
      font-size: 20px;
    }

    .profile-link > div {
      width: $side-nav-width * 0.75;
      height: $side-nav-width * 0.75;
      font-size: 15px;
      letter-spacing: -2px;
    }

    .icon-link i {
      font-size: 25px;
    }

    .profile-link:hover, .icon-link:hover {
      text-decoration: none;
    }

    .active {
      background: #f1f1f1;
    }
  }

  .side-nav .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  }

  .overlay-icon {
    font-size: 50px;
  }
}
