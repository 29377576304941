$cell-width: 75px;

.calendar {
  .cal-cell {
    width: $cell-width;
    height: $cell-width;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .date-number {
      position: absolute;
      top: 0;
      left: 3px;
      font-size: 15px;
      color: $secondary;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      text-align: center;
    }
  }

  .days {
    .cal-cell {
      height: $cell-width/2;
      font-weight: bold;
    }
  }

  .body {
    .cal-cell {
      margin-left: -1px;
      margin-top: -1px;
    }

    .cal-row:first-child {
      .cal-cell:first-child {
        border-top-left-radius: 0.25rem !important;
      }

      .cal-cell:last-child {
        border-top-right-radius: 0.25rem !important;
      }
    }

    .cal-row:last-child {
      .cal-cell:first-child {
        border-bottom-left-radius: 0.25rem !important;
      }

      .cal-cell:last-child {
        border-bottom-right-radius: 0.25rem !important;
      }
    }
  }

  .cal-row {
    display: flex;
  }

  .btn-container {
    width: calc(100% - 7px);
  }

  .bg-disabled {
    background: #f1f1f1;
  }

  .bg-danger, .bg-success {
    .date-number {
      color: white !important;
    }
  }

  .month-control {
    cursor: pointer;
  }

}

.calendar-context-menu {
  .icon-container {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    font-size: 18px;
  }
}
