@import url('https://fonts.googleapis.com/css?family=Khand:600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    //background: white;
    background: transparent;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

html, body, #root, .App {
  height: 100%;
}

$enable-shadows: true;
$main-color: #3eb396;

$theme-colors: (
        primary: $main-color
);

.bg-main {
  background: $main-color;
}

.bg-transparent {
  background: transparent;
}

.bg-white {
  background: white;
}

.font-main {
  font-family: 'Khand', sans-serif;
}

.font-secondary {
  font-family: 'Montserrat', sans-serif;
}

.color-main {
  color: $main-color;
}

.text-big {
  font-size: 25px;
}

.text-medium {
  font-size: 22px;
}

.text-small {
  font-size: 10px;
}

.cursor-pointer {
  cursor: pointer !important;
}

._loading_overlay_overlay {
  background: $main-color !important;
  opacity: 0.65 !important;
}

.react-contexify {
  border-radius: 0.25rem !important;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
  background: $main-color !important;
}

.react-contexify__item {
  font-size: 15px;
}

.table th {
  border-top: none !important;
}

.max-height-100 {
  max-height: 100%;
}

.show-on-hover {
  * {
    visibility: hidden;
  }
}

.show-on-hover:hover {
  * {
    visibility: visible;
  }
}

@import "~bootstrap/scss/bootstrap";

@import "App";
@import "pages/auth-page";
@import "pages/profile-page";
@import "pages/vacation-request-page";
@import "pages/users-page";
@import "pages/month-report-page";

@import "components/navigation";
@import "components/calendar";
@import "components/loading-screen";
